import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import './HeroSection.css';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

function HeroSection() {
  const navigate = useNavigate(); // Hook for navigation
  const { instance } = useMsal(); // MSAL instance from useMsal hook
  const [error, setError] = useState(''); // State for storing login errors

  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0); // new addition

  const handleLogin = () => {
    instance.loginPopup({
      scopes: ["openid", "profile"] // Add any other scopes required by the application
    }).then(response => {

      // Successful login
      // The 'response' object contains the authentication response.
      // You can use this response to get the account info, tokens, etc.

      // Successful login
      console.log("Login was successful!", response);
      navigate('/'); // Redirect to the homepage

      // If needed, you can access the account info like this:
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        // Grab the first account in the array for subsequent use
        const account = accounts[0];
        console.log("Logged in account:", account);
      }

    }).catch(error => {
      // Login failed
      // console.error("Login failed", error);
      // Update the error state with the error message
      setError('Login failed. Please try again.');
      setTimeout(() => setError(''), 2000);
    });
  };


  const [sliderValue, setSliderValue] = useState(5);
  const [usage, setUsage] = useState({
    dailyCommute: false,
    roadTrips: false,
    offRoad: false,
    sport: false,
  });

  // const [isSpinning, setIsSpinning] = useState(true); // State to control the spinning

  const askTheWiz = async () => {
    setIsLoading(true); // Start the loading process
    setLoadingProgress(0); // new addition

    // // Log the selected budget value
    // console.log("Selected budget:", budget);

    // Simulating progress
    const interval = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          setIsLoading(false);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 1000); // Adjust timing as necessary


    // Split budget into minBudget and maxBudget to support UI ranges
    const budgetRange = budget.split("-");
    const minBudget = budgetRange[0];
    const maxBudget = budgetRange.length === 2 ? budgetRange[1] : '';

    // Log data types
    // console.log("Data Type of minBudget:", typeof minBudget);
    // console.log("Data Type of maxBudget:", typeof maxBudget);

    // Log the budget range
    // console.log("Budget Range:", `Min: ${minBudget}`, `Max: ${maxBudget || 'No upper limit'}`);

    const queryParams = new URLSearchParams({
      minBudget: minBudget,
      maxBudget: maxBudget,
      make: carMake,
      bodyType: carBodyType,
      years: sliderValue,
      // Add other usage parameters if needed
    }).toString();

    // console.log("Query Params:", queryParams);

    const functionUrl = `https://carprojfunctionapp.azurewebsites.net/api/GetUserInputs?code=SKj6nqCfxwoFd4AmXg1HRlObDQ91q_yj6tHWYLa-y4aQAzFuVmXRaw==&${queryParams}`;

    try {
      const response = await fetch(functionUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const cars = await response.json();
      // console.log('API call successful:', cars);


      navigate('/user-results', { state: { cars } });
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
    finally {
      setIsLoading(false); // Stop the loading process regardless of the outcome
    }
  };

  const [budget, setBudget] = useState('');

  const [carMake, setCarMake] = useState('');
  const [carBodyType, setCarBodyType] = useState('');

  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
  };

  const handleCarMakeChange = (event) => {
    setCarMake(event.target.value);
  };

  const handleCarBodyTypeChange = (event) => {
    setCarBodyType(event.target.value);
  };

  const handleBodyTypeSelect = (bodyType) => {
    console.log("Selected body type:", bodyType);
    switch (bodyType) {
      case 'Sedans':
        navigate('/sedans');
        break;
      case 'SUVs':
        navigate('/suvs');
        break;
      case 'Trucks':
        navigate('/trucks');
        break;
      default:
        break;
    }
  };

  const handleYearChange = (event) => {
    setSliderValue(event.target.value);
  };

  const handleUsageChange = (event) => {
    const { name, checked } = event.target;
    setUsage((prevUsage) => ({ ...prevUsage, [name]: checked }));
  };

  return (

    <div className='hero-container'>
      {error && <p className="Login failed, please try again or enjoy our free search which doesn't require it.">{error}</p>}
      {/* Body Styles would be above the title */}
      <div className='body-styles'>
        <div onClick={() => handleBodyTypeSelect('SUVs')} className='body-style'>
          <img src='/images/suv.jpg' alt='SUVs' />
          <span>SUVs</span>
        </div>
        <div onClick={() => handleBodyTypeSelect('Sedans')} className='body-style'>
          <img src='/images/sedan.jpg' alt='Sedans' />
          <span>Sedans</span>
        </div>
        <div onClick={() => handleBodyTypeSelect('Trucks')} className='body-style'>
          <img src='/images/pickup-truck.jpg' alt='Trucks' />
          <span>Trucks</span>
        </div>
      </div>

      <h2>Find Your Fit, Hit the Road.</h2>

      <div className='options-container'>

        <div className='row'>

          <div className='duration-container'>
            <p className='option-label'>Own for: </p>
            <label htmlFor="year-slider" className='option-value'>{sliderValue} years</label>
            <input
              type="range"
              id="year-slider"
              name="years"
              min="0.5"
              max="10.0"
              step="0.5"
              value={sliderValue}
              onChange={handleYearChange}
            />
          </div>

          <div className='usage-container'>
            <p className='option-label'>Use for:</p>
            <div className='checkbox-group'>

              <label>
                <input
                  type="checkbox"
                  name="roadTrips"
                  checked={usage.roadTrips}
                  onChange={handleUsageChange}
                />
                Road Trips
              </label>

              <label>
                <input
                  type="checkbox"
                  name="dailyCommute"
                  checked={usage.dailyCommute}
                  onChange={handleUsageChange}
                />
                Daily Commute
              </label>
              <label>
                <input
                  type="checkbox"
                  name="offRoad"
                  checked={usage.offRoad}
                  onChange={handleUsageChange}
                />
                Off-Road Adventures
              </label>
              <label>
                <input
                  type="checkbox"
                  name="sport"
                  checked={usage.sport}
                  onChange={handleUsageChange}
                />
                Sport & Performance
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='budget-container'>
            <p className='option-label'>Budget: </p>
            <select value={budget} onChange={handleBudgetChange}>
              <option value="">Select Budget</option>
              <option value="0-20000">$0 - $20,000</option>
              <option value="20001-40000">$20,001 - $40,000</option>
              <option value="40001-60000">$40,001 - $60,000</option>
              <option value="60001-80000">$60,001 - $80,000</option>
              <option value="80001-100000">$80,001 - $100,000</option>
              <option value="100000+">$100,000+</option>
            </select>
          </div>

          <div className='car-selection-container'>
            <div className='car-make-container'>
              <p className='option-label'>Car Make: </p>

              <select value={carMake} onChange={handleCarMakeChange}>
                <option value="">Select Make</option>
                <option value="Acura">Acura</option>
                <option value="Audi">Audi</option>
                <option value="BMW">BMW</option>
                <option value="Buick">Buick</option>
                <option value="Cadillac">Cadillac</option>
                <option value="Chevrolet">Chevrolet</option>
                <option value="Chrysler">Chrysler</option>
                <option value="Dodge">Dodge</option>
                <option value="Ford">Ford</option>
                <option value="GMC">GMC</option>
                <option value="Genesis">Genesis</option>
                <option value="Honda">Honda</option>
                <option value="Hyundai">Hyundai</option>
                <option value="INFINITI">INFINITI</option>
                <option value="Jaguar">Jaguar</option>
                <option value="Jeep">Jeep</option>
                <option value="Kia">Kia</option>
                <option value="Land Rover">Land Rover</option>
                <option value="Lexus">Lexus</option>
                <option value="Lincoln">Lincoln</option>
                <option value="MAZDA">MAZDA</option>
                <option value="MINI">MINI</option>
                <option value="Mercedes-Benz">Mercedes-Benz</option>
                <option value="Mitsubishi">Mitsubishi</option>
                <option value="Nissan">Nissan</option>
                <option value="Porsche">Porsche</option>
                <option value="Ram">Ram</option>
                <option value="Subaru">Subaru</option>
                <option value="Toyota">Toyota</option>
                <option value="Volkswagen">Volkswagen</option>
                <option value="Volvo">Volvo</option>

              </select>
            </div>

            <div className='car-bodytype-container'>
              <p className='option-label'>Body Type: </p>
              <select value={carBodyType} onChange={handleCarBodyTypeChange}>
                <option value="">Select Body Type</option>
                <option value="SUV">SUV</option>
                <option value="Sedan">Sedan</option>
                <option value="Pickup">Truck</option>
                {/* ... other body types ... */}

              </select>
            </div>
          </div>
        </div>
        <AuthenticatedTemplate>
          {/* This content is only visible to authenticated users */}
          <div className='submit-button-container'>
            <button
              className={`submit-btn ${isLoading ? 'loading' : ''}`}
              onClick={askTheWiz}
              disabled={isLoading}
            >
              {isLoading ? <div className="progress-bar" style={{ width: `${loadingProgress}%` }}></div> : 'Ask the Wiz'}
            </button>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div className='submit-button-container'>
            <button className='submit-btn' onClick={handleLogin}>

              Sign in to Ask the Wiz
            </button>
          </div>
        </UnauthenticatedTemplate>

      </div>
    </div>
  );
}

export default HeroSection;