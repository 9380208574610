import React from 'react';
import HeroSection from '../components/HeroSection';
import './Home.css';

const Home = () => {
    return (
        <div className="home-container">
            <header>

            </header>

            <main>

                <HeroSection />
                
            </main>

            <footer>

            </footer>
        </div>
    );
};

export default Home;
