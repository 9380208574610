import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './VehicleTypeNav.css'; // Ensure the CSS file is imported

const VehicleTypeNav = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('');

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedType(value);
    if (value) {
      navigate(`/${value}`); // Navigate to the selected vehicle type route
    }
  };

  return (
    <div className="vehicle-type-nav">
      <select onChange={handleSelectChange} value={selectedType} className="vehicle-type-select">
        <option value="">Body Type</option>
        <option value="sedans">Sedans</option>
        <option value="suvs">SUVs</option>
        <option value="trucks">Trucks</option>
      </select>
    </div>
  );
};

export default VehicleTypeNav;
