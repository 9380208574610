import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>Contact us: <a href="mailto:contact@CarWiz.ai">info@CarWiz.ai</a></p>
                <div className="footer-links">
                    <Link to="/about">About</Link>
                    <Link to="/termsandconditions">Terms and Conditions</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    {/* LinkedIn Link */}
                    <a href="https://www.linkedin.com/company/car-wiz/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </div>
                <div className="copyright">
                    <p>© {new Date().getFullYear()} CarWiz. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
