import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms and Conditions for CarWiz</h1>

            <h2>1. Introduction</h2>
            <p>Welcome to CarWiz. By accessing our website and using our car recommendation services, you agree to be bound by the following terms and conditions. Please read them carefully.</p>

            <h2>2. Data Collection and Use</h2>
            <p>(a) At CarWiz, we collect personal data that you provide to us when you register for an account, update or change information for your account, use our car recommendation services, complete surveys, sign up for email updates, or otherwise communicate with us.</p>
            <p>(b) The data we collect includes, but is not limited to, your name, email address, driving preferences, and vehicle requirements.</p>
            <p>(c) We use this data to enhance our AI-driven car recommendation services, personalize your experience, communicate with you, and for internal analytics and reporting purposes.</p>

            <h2>3. Data Sharing and Disclosure</h2>
            <p>(a) CarWiz does not sell, rent, or otherwise share your personal data with third parties for marketing purposes without your explicit consent.</p>
            <p>(b) We may disclose your data to comply with legal obligations, to protect and defend our rights or property, or in emergency situations to protect personal safety.</p>

            <h2>4. Data Security</h2>
            <p>CarWiz implements a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.</p>

            <h2>5. User Responsibilities</h2>
            <p>(a) You are responsible for maintaining the confidentiality of your account and password and for restricting access to your devices.</p>
            <p>(b) You agree to accept responsibility for all activities that occur under your account or password.</p>

            <h2>6. Amendments to Terms and Conditions</h2>
            <p>CarWiz reserves the right to amend these terms and conditions at any time. All amendments to these terms will be posted online. Continued use of the site will be deemed to constitute acceptance of the new terms and conditions.</p>

            <h2>7. Contact Us</h2>
            <p>If you have any questions about these Terms and Conditions, please contact us at info@carwiz.ai</p>
        </div>
    );
}

export default TermsAndConditions;
