import React, { useEffect, useState } from 'react';
import './Users.css'; 

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://carprojfunctionapp.azurewebsites.net/api/GetUsers?code=j6C-vLrrBTdQnVSQYpkmoE_YH_-7F7deE6RrkeRcll9RAzFus8cUhQ==')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsers(data); // No need for additional parsing if the data is already in the correct format
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    if (users.length === 0) {
        return <div>No users to display.</div>;
    }

    return (
        <div>
            <p>Page for data pull validation </p>
            <h2>Users List</h2>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}> 
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UsersList;
