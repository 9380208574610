import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h2>About CarWiz</h2>
      <p>Welcome to CarWiz: Redefining Car Selection with AI Precision.</p>
      <p>
        At CarWiz, we blend advanced AI technology with deep automotive expertise to offer you a personalized car selection experience. We understand that choosing the right car is about matching it to your unique lifestyle, whether for a weekend getaway or a long-term commitment.
      </p>
      <p>
        Our platform goes beyond the basics of car-finding; it's an intelligent companion guiding you through your car selection journey. By leveraging sophisticated algorithms and data-driven insights, CarWiz provides vehicle recommendations tailored specifically to your individual needs and preferences.
      </p>
      <p>
        What sets CarWiz apart is our unique combination of cutting-edge technology and human intuition. Our team comprises passionate automotive enthusiasts and AI specialists dedicated to ensuring that each recommendation is data-informed and intuitively aligned with your driving requirements.
      </p>
      <p>
        We are committed to transforming the car selection process into an informative, effortless, and enjoyable experience. With CarWiz, you're not just picking a car; you're discovering a smarter way to find the vehicle that seamlessly fits into your life.
      </p>
      <h3>Join Our Community</h3>
      <p>
        Be part of the revolution in AI-driven, personalized car selection. Connect with CarWiz and experience how we bring clarity, confidence, and a touch of magic to your vehicle choice.
      </p>
      <h3>Ready to find your perfect car match?</h3>
      <p>
        Explore CarWiz today — Where AI Meets the Road.
      </p>
    </div>
  );
}

export default About;
