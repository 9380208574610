import React, { useState, useEffect } from 'react';
import './Search.css'; 
import VehicleTypeNav from '../components/VehicleTypeNav'; 

const CarCard = ({ car }) => {
    const defaultImage = process.env.PUBLIC_URL + '/images/shake-hand-buy-car.jpg';
    const [isFlipped, setIsFlipped] = useState(false);

    // Error handling for image loading
    const handleError = (e) => {
        e.target.src = defaultImage;
    };

    // Toggle the flipped state on card click
    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    // Format the price to include commas
    // const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(car.price);
    const formatCurrency = (number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(number);
        
    };

    return (
        <div className={`car-card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
            <div className="car-card-inner">
                <div className="car-card-front">
                    <div className="car-image">
                        <img
                            src={car.imagesURL || defaultImage}
                            alt={`${car.make} - ${car.model} - ${car.yearOfManufacture}`} 
                            onError={handleError}
                        />
                    </div>
                    <div className="car-info">
                        <h3>{car.make} - {car.model} ({car.yearOfManufacture})</h3>
                        <div className="car-details">
                            <span>MPG: {car.mpg}</span>
                            <span>Price: {formatCurrency(car.price)}</span> 
                        </div>
                    </div>
                </div>
                <div className="car-card-back">
                    <div className="car-info">
                        <img src='/images/cw.jpg' alt='cw' className="car-info-image" />
                        <h3>CarWiz</h3>
                        <p>Recalls: {car.recalls}</p>
                        <p>Safety Rating: {car.safetyRating}</p>
                        <p>Seating Capacity: {car.seatingCapacity}</p>
                        <p>Cost to Drive: {formatCurrency(car.costToDrive)}</p>
                        <p>Transmisson: {car.transmissionType}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Search = () => {
    const [cars, setCars] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // New loading state

    useEffect(() => {
        setIsLoading(true); // Set loading to true before fetching data
        fetch(process.env.REACT_APP_GET_CARS_FUNCTION_URL) // GET Cars URL
            .then(response => response.json())
            .then(data => {
                setCars(data);
                setIsLoading(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                console.error('Error fetching cars:', error);
                setIsLoading(false); // Set loading to false if there is an error
            });
    }, []);

    return (
        <div className="search-container">
            <VehicleTypeNav />
            {/* ...search input and button... */}
            <div className="cars-container">
                {isLoading ? (
                    <div className="spinner"></div> // Referencing the spinner here
                ) : cars.length > 0 ? (
                    cars.map(car => <CarCard key={car.CarID} car={car} />)
                ) : (
                    <p>Sorry the database fell into a deep sleep. Please refresh.</p>
                )}
            </div>
        </div>
    );
};

export default Search;