import React from 'react';
import './Privacy.css'; // Import the CSS file for styling

const Privacy = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy for CarWiz</h1>
            <p>Last Updated: December 25, 2023</p>

            <h2>1. Introduction</h2>
            <p>CarWiz respects the privacy of our users and is committed to protecting your personal information.
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you
                visit our website and use our car recommendation services.</p>

            <h2>2. Introduction</h2>

            <h3>(a) Personal Identification Information: </h3>
            <p> We may collect personal identification information from Users in various ways, including, but
                not limited to, when Users visit our site, register on the site, fill out a form, and in
                connection with other activities, services, features, or resources we make available.
                Users may be asked for, as appropriate, name, email address, and other details.</p>

            <h3>(a) Non-Personal Identification Information: </h3>
            <p>We may collect non-personal identification information about Users whenever they interact with our Site.
                This may include the browser name, the type of computer, and technical information about Users' means
                of connection to our Site, such as the operating system and the Internet service providers utilized,
                and other similar information. </p>

            <h2>3. Use of Collected Information</h2>
            <p> CarWiz may collect and use Users' personal information for the following purposes:
                - To personalize user experience
                - To improve our Site
                - To send periodic emails (if you opt-in) </p>

            <h2>4. Web Browser Cookies</h2>
            <p> CarWiz may collect and use Users' personal information for the following purposes:
                Our Site may use "cookies" to enhance the User experience. Users' web browsers place cookies on their
                hard drives for record-keeping purposes and sometimes to track information about them. Users may
                choose to set their web browser to refuse cookies or to alert them when cookies are being sent.</p>


            <h2>5. Protection of Your Information</h2>
            <p>We adopt appropriate data collection, storage, and processing practices and security measures to
                protect against unauthorized access, alteration, disclosure, or destruction of your personal
                information, username, password, transaction information, and data stored on our Site. </p>


            <h2>6. Sharing Your Information</h2>
            <p>We do not sell, trade, or rent Users' personal identification information to others.
                We may share generic aggregated demographic information not linked to any personal
                identification information regarding visitors and users with our business partners,
                trusted affiliates, and advertisers.</p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>CarWiz has the discretion to update this privacy policy at any time. When we do, we will post a
                notification on the main page of our Site. We encourage Users to frequently check this page
                for any changes to stay informed about how we are helping to protect the personal information
                we collect.</p>

            <h2>8. Your Acceptance of These Terms</h2>
            <p>By using this Site, you signify your acceptance of this policy. If you do not agree stop using it right
                away and email CarWiz.ai to delete anu information of yours collected thus far. </p>
                </div>
    );
}

export default Privacy;