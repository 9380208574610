import React, { useEffect, useState } from 'react';

const Cart = () => {
    const [cars, setCars] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://carprojfunctionapp.azurewebsites.net/api/GetUsers?code=j6C-vLrrBTdQnVSQYpkmoE_YH_-7F7deE6RrkeRcll9RAzFus8cUhQ==') // Replace with your actual API URL
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCars(data);
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (cars.length === 0) {
        return <div>No cars to display.</div>;
    }

    return (
        <div>
            <h2>Cars List</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Model</th>
                        <th>Year of Manufacture</th>
                    </tr>
                </thead>
                <tbody>
                    {cars.map((car) => (
                        <tr key={car.id}> {/* Assuming each car has a unique 'id' */}
                            <td>{car.name}</td> {/* Make sure the property names match your data */}
                            <td>{car.model}</td>
                            <td>{car.yearOfManufacture}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Cart;
