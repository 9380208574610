import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Search from './pages/Search';
import Favorites from './pages/Favorites';
import Cart from './pages/Cart';
import Users from './pages/Users';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Sedans from './pages/Sedans';
import SUVs from './pages/SUVs';
import Trucks from './pages/Trucks';
import About from './pages/About';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import UserResults from './pages/UserResults';
import Chatbot from './components/Chatbot'; // Import Chatbot component

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <Navbar /> {/* Navbar displayed on all pages */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/users" element={<Users />} />
          <Route path="/sedans" element={<Sedans />} />
          <Route path="/suvs" element={<SUVs />} />
          <Route path="/trucks" element={<Trucks />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/about" element={<About />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/user-results" element={<UserResults />} />
          <Route path="/chatbot" element={<Chatbot />} /> 
        </Routes>
        <Footer /> {/* Footer displayed on all pages */}
      </Router>
    </MsalProvider>

  );
}

export default App;
