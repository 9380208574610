export const msalConfig = {
    auth: {
        clientId: "2074e91f-7ab3-4e3d-b63e-071f4f7d3442", // This is your application/client ID in Azure AD B2C
        authority: "https://carproj.b2clogin.com/carproj.onmicrosoft.com/B2C_1_signupsignin1", // Example: https://{tenant}.b2clogin.com/{tenant}.onmicrosoft.com/B2C_1_SignInSignUp
        knownAuthorities: ["carproj.b2clogin.com"], // Your `{tenant}.b2clogin.com`
        redirectUri: "https://carwiz.ai/", // Or your redirect URI
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set to true if you have issues on browsers that block third-party cookies
    },
};
