import React, { useState } from 'react';  // Include useState here
import './Navbar.css';
import { Link } from 'react-router-dom';
// import { useMsal } from '@azure/msal-react';
// import useAuthState from '../useAuthState'; // Path to your custom hook

const Navbar = () => {
    // const isAuthenticated = useAuthState();
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    // const { instance } = useMsal();
    // const isAuthenticated = useIsAuthenticated();

    return (
        <nav className="navbar">
            {/* Logo or Brand Name */}
            <div className="navbar-brand">
                <Link to="/">CarWiz</Link>
            </div>

            {/* Hamburger Icon */}
            <button
                className="hamburger"
                onClick={() => setIsNavExpanded(!isNavExpanded)}
            >
                {/* Icon or Text */}
                ☰
            </button>

            {/* Navigation Links */}
            <div className={`navbar-links ${isNavExpanded ? "expanded" : ""}`}>
                <Link to="/" onClick={() => setIsNavExpanded(false)}>Home</Link>
                <Link to="/search" onClick={() => setIsNavExpanded(false)}>Search</Link>
                <Link to="/chatbot" onClick={() => setIsNavExpanded(false)}>Chatbot</Link> {/* Add Chatbot link */}
                {/* <Link to="/favorites" onClick={() => setIsNavExpanded(false)}>🤍</Link> */}
            </div>
        </nav>
    );
};

export default Navbar;
